<template>
  <div class="new-ticket-wrapper">
    <SignupOverlay>
      <Heading level="4"> Бронювання </Heading>
      <Stepper :steps="steps" />
      <router-view></router-view>
    </SignupOverlay>
  </div>
</template>

<script>
import SignupOverlay from '@/components/Signup/SignupOverlay.vue';
import Heading from '@/elements/Heading/Heading.vue';
import Stepper from '@/elements/Stepper/Stepper.vue';

export default {
  components: {
    SignupOverlay,
    Heading,
    Stepper,
  },
  data() {
    return {
      steps: [
        {
          title: 'Пункт пропуску',
          name: 'NewTicketCustomsPost',
        },
        {
          title: 'Інформація про ТЗ',
          name: 'NewTicketInfo',
        },
        {
          title: 'Дата та час заїзду',
          name: 'NewTicketDate',
        },
        {
          title: 'Оплата',
          name: 'NewTicketPaymentChoice',
        },
      ],
    };
  },
};
</script>

<style lang="sass">
.new-ticket-wrapper>.signup-overlay>.title
  text-align: center
  margin-top: 0
  margin-bottom: 0

.new-ticket-wrapper .signup-control-buttons
  flex-direction: row!important

.new-ticket-wrapper .signup-control-buttons .primary-btn
  @media screen and (max-width: 600px)
    height: 44px!important
    font-size: 14px!important
</style>
